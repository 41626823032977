import React from 'react';
import LinkWithQuery from '../../LinkWithQuery';

export default function ArticleCard({ url, title, description }) {
	return (
		<article className="c-resources-card">
			<LinkWithQuery to={url} className="c-resources-card__link">
				<div className="o-row-2">
					<h3 className="c-resources-card__title">
						{title}
					</h3>
				</div>
				<div className="o-row-2">
					<p className="c-resources-card__copy">
						{description}...
					</p>
				</div>
				<p className="c-resources-card__copy c-resources-card__copy--read-more">
					<strong>Read more</strong>
				</p>
			</LinkWithQuery>
		</article>
	);
}
