import React from 'react';
import LinkWithQuery from '../LinkWithQuery';
import cleanCrumbLabels from '../../utils/cleanCrumbLabels';

const capitalizeSpecificText = (text, pathname) => {
	const specificTexts = ['ix']; // List of texts to capitalize
	let newText = text;

	specificTexts.forEach((specificText) => {
		const regex = new RegExp(`\\b${specificText}\\b`, 'gi'); // Case-insensitive match
		newText = newText.replace(regex, (match) => match.toUpperCase());
	});

	// Handling special case for "Lossanddamages"
	if (pathname === '/lossanddamages') {
		newText = 'Loss and Damages';
	}

	if (pathname === '/lossanddamages-success') {
		newText = 'Loss and Damages Success';
	}

	if (pathname === '/resources/videos/horizon-bte') {
		newText = 'Horizon BTE';
	}

	if (pathname === '/resources/videos/signia-bte') {
		newText = 'Signia BTE';
	}

	return newText;
};

const CustomBreadcrumb = ({ location, crumbLabel, crumbs, ...rest }) => {
	const cleanCrumbs = cleanCrumbLabels(crumbs);
	return (
		<nav className="breadcrumb" aria-label="Breadcrumb">
			<ol className="breadcrumb__list">
				{cleanCrumbs.map((c, i) => (
					<li className="breadcrumb__list__item" key={i}>
						<LinkWithQuery
							to={c.pathname || ''}
							className="breadcrumb__link"
							activeClassName="breadcrumb__link__active"
							aria-current={i === cleanCrumbs.length - 1 ? 'page' : null}
							{...rest}
						>
							{capitalizeSpecificText(c.crumbLabel, c.pathname)}
						</LinkWithQuery>
						{i === cleanCrumbs.length - 1 ? null : (
							<span className="breadcrumb__separator" aria-hidden="true" style={{ fontWeight: 400 }}>
								{' '}
								›{' '}
							</span>
						)}
					</li>
				))}
			</ol>
		</nav>
	);
};

export default CustomBreadcrumb;
