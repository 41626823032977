/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    ul: "ul",
    li: "li",
    ol: "ol",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components), {StateCta, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "hydrogen-peroxide-in-your-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hydrogen-peroxide-in-your-ear",
    "aria-label": "hydrogen peroxide in your ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hydrogen Peroxide in Your Ear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-impaired-phone-hero.png",
    alt: "Leaf falling from the sky",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-you-should-not-use-ear-candles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-you-should-not-use-ear-candles",
    "aria-label": "why you should not use ear candles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why you should NOT use ear candles"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hydrogen peroxide is a common household item often used for cleaning and disinfecting wounds. But can it be safely used in your ears? This article will explore the safety and effectiveness of hydrogen peroxide for ear cleaning, provide proper ear cleaning methods, and discuss its relation to hearing health."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-hydrogen-peroxide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-hydrogen-peroxide",
    "aria-label": "what is hydrogen peroxide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Hydrogen Peroxide?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hydrogen peroxide (H2O2) is a mild antiseptic used for various medical and household purposes. It works by releasing oxygen when it comes into contact with tissues, causing foaming that helps remove dead skin cells and debris."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "is-it-safe-to-use-hydrogen-peroxide-in-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#is-it-safe-to-use-hydrogen-peroxide-in-your-ears",
    "aria-label": "is it safe to use hydrogen peroxide in your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Is It Safe to Use Hydrogen Peroxide in Your Ears?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Using hydrogen peroxide in your ears can be safe when done correctly and in moderation. Here are some key points to consider:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dilution: Always use a diluted solution (typically 3%) to avoid irritation or damage to the delicate tissues in the ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Frequency: Overuse can lead to dryness, irritation, or damage to the ear canal. It's best to limit use to occasional cleaning."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Existing Conditions: Avoid using hydrogen peroxide if you have an ear infection, a perforated eardrum, or any other ear condition without consulting a healthcare professional."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-clean-your-ears-safely-with-hydrogen-peroxide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-clean-your-ears-safely-with-hydrogen-peroxide",
    "aria-label": "how to clean your ears safely with hydrogen peroxide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Clean Your Ears Safely with Hydrogen Peroxide"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Follow these steps for safe and effective ear cleaning with hydrogen peroxide:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Prepare the Solution"), ": Use a 3% hydrogen peroxide solution. You may dilute it further with equal parts water if desired."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Lie Down"), ": Lie on your side with the affected ear facing up."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Apply the Solution"), ": Using a dropper, place a few drops (5-10) of the solution into the ear canal."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Let it Sit"), ": Allow the solution to sit in the ear for 5-10 minutes. You may hear bubbling and fizzing, which is normal."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Drain and Rinse"), ": Turn your head to let the solution drain out onto a clean towel. Rinse the ear gently with warm water."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Dry Your Ear"), ": Gently pat the outside of your ear with a towel to dry."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "alternatives-to-hydrogen-peroxide-in-your-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#alternatives-to-hydrogen-peroxide-in-your-ear",
    "aria-label": "alternatives to hydrogen peroxide in your ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Alternatives to Hydrogen Peroxide in your Ear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you prefer not to use hydrogen peroxide, there are other safe methods for ear cleaning:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear Drops: Over-the-counter ear drops can help soften and remove earwax."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Irrigation Kits: Ear irrigation kits use a gentle stream of water to flush out earwax."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Professional Cleaning: Regular visits to an audiologist or healthcare provider for professional ear cleaning can prevent buildup and maintain ear health."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "relation-to-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#relation-to-hearing-loss",
    "aria-label": "relation to hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Relation to Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Earwax buildup can sometimes cause temporary hearing difficulties, leading to the suspicion of hearing loss. It’s essential to distinguish between temporary blockages and more permanent hearing issues. If you frequently struggle to hear conversations, feel the need to increase the volume on your devices, or experience ringing in your ears, you might be dealing with more than just earwax buildup. In such cases, a professional hearing evaluation is crucial."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Untreated hearing loss can significantly impact your quality of life, leading to social isolation, cognitive decline, and emotional challenges. Early detection and intervention are key to managing hearing loss effectively."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-can-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-can-help",
    "aria-label": "how hearcom can help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hear.com Can Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you suspect hearing loss, hear.com offers comprehensive solutions tailored to your needs. Our process is designed to be simple and accessible, ensuring you get the help you need without hassle."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "our-process-is-straightforward",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-process-is-straightforward",
    "aria-label": "our process is straightforward permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our process is straightforward:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fill out the questionnaire on our website."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Receive a call from a hearing expert who will guide you through your options."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Get your hearing aids delivered to your home in 48 hours or less."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Visit an audiologist near your location for a professional fitting."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Try out your hearing aids for 45 days to ensure they meet your needs."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With a commitment to accessibility and quality, hear.com provides hearing aids to fit any budget. Our network includes the top 2% of audiologists in the United States, ensuring you receive expert care and personalized support."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(StateCta, {
    backgroundColor: "white"
  }), " I"), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
